import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "pixelsimage"
    }}>{`Pixels/Image`}</h1>
    <p>{`For users who want the flexibility to embed tokens into documents which we do not yet support or in other creative ways, we allow you to create naked references to individual pixel images.`}</p>
    <p>{`These tokens are references to 1x1 pixel transparent gifs.  Potential use cases include:`}</p>
    <ul>
      <li parentName="ul">{`embedding them in an `}<inlineCode parentName="li">{`img`}</inlineCode>{` tag a webpage that users should not access.`}</li>
      <li parentName="ul">{`embedding them in other document types related to a specific industries.`}</li>
      <li parentName="ul">{`embedding them in other custom programming applications as needed.`}</li>
    </ul>
    <p><strong parentName="p">{`WE WANT TO HEAR FROM YOU!!!`}</strong>{` If you've created unique ways to embed tokens, please let us know! We would love to hear how our users are creatively deploying Dragnet, and we might be able to incorporate your methods into Dragnet!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      